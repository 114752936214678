import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/core/services/main.service';
import { ServiceService } from '../service.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  object = null;
  filter = { page: 1 };
  page = 1;
  pageSize = 0;
  total = 0;
  parent_id = null;

  constructor(
    private mainService: MainService,
    private service: ServiceService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
  ) {

    this.activatedRoute.params.subscribe(params => {
      this.parent_id = params.parent_id;
      this.setBreadCrumbItems();
      this.getIndexData();
    });


  }

  setBreadCrumbItems() {
    if (this.parent_id) {
      this.mainService.changeBreadCrumbItems([
        { label: 'Vehicle Models', path: '/dashboard/users/vehicle_models', active: false },
        { label: 'Vehicle Models ' + this.parent_id + ' SUb', path: '', active: true },
      ]);
      this.mainService.setTitle('Vehicle Models ' + this.parent_id + ' SUb List');
    }
    else {
      this.mainService.changeBreadCrumbItems([
        { label: 'Vehicle Models ', path: '', active: true },
      ]);
      this.mainService.setTitle("Vehicle Models List");
    }
  }

  ngOnInit() {

  }

  getIndexData() {
    this.service.getAll(this.filter, this.parent_id).subscribe((data: any) => {
      this.object = data.data;
      this.total = data.data.total;
      this.pageSize = data.data.per_page;
    });
  }

  pageChange(page: number) {
    this.filter.page = page;
    this.getIndexData();
  }

  deleteObject(id) {
    this.service.deleteObject(id).subscribe((data: any) => {
      if (data.status == 1) {
        this.mainService.sweetAlertMessage("Deleted!", 'Your file has been deleted.');
        this.mainService.show_notifications("Successfully Deleted");
      }
      if (data.status == 0) {
        this.mainService.sweetAlertMessage("Deleted!", "Your file has't been deleted.", 'error');
        this.mainService.showFormErrors(data.errors);
      }
      this.getIndexData();
    });
  }

}
