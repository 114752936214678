export class formObject {

    formGroup = null;
    submit = false;
    loader = false;
    submitAction = null;

    get controller() { return this.formGroup.controls; }

    ui_switch_change_status(controller) {
        controller.setValue(controller.value == 1 ? 0 : 1);
    }

    ui_switch_change_status_reassign(controller) {
        controller.setValue(controller.value == -1 ? 0 : -1);
    }
}