import { Component, OnInit } from '@angular/core';
import { WOW } from 'wowjs';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  count1 = 0;
  count2 = 0;
  count3 = 0;

  constructor() { }

  ngOnInit() {
    new WOW().init();
    this.startCounters();
  }

  startCounters(): void {
    this.animateCounter('count1', 2331777, 7000);
    this.animateCounter('count2', 1427367, 10000);
    this.animateCounter('count3', 24368, 20000);
  }

  animateCounter(property: string, to: number, duration: number): void {
    let start = 0;
    const stepTime = Math.abs(Math.floor(duration / to));
    const obj = this;
    const timer = setInterval(function () {
      start++;
      obj[property] = start;
      if (start === to) {
        clearInterval(timer);
      }
    }, stepTime);
  }

}
