import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MainService } from 'src/app/core/services/main.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-history-routes',
  templateUrl: './history-routes.component.html',
  styleUrls: ['./history-routes.component.scss']
})
export class HistoryRoutesComponent implements OnInit {
  active_route = null;
  max = 3;
  routes = [
    // { url: "/", label: "Dashboards" },
    { url: "/users/dashboards", label: "Dashboards" },
    { url: "/users/languages/create", label: 'Languages Create' },
    { url: "/users/languages/edit/:id", label: 'Language :id Edit' },
    { url: "/users/languages", label: 'Languages' },

    { url: "/users/governments/create", label: 'Governments Create' },
    { url: "/users/governments/edit/:id", label: 'Government :id Edit' },
    { url: "/users/governments", label: 'Governments' },
    { url: "/users/governments/:government_id/cities", label: 'Government :government_id Cities' },
    { url: "/users/governments/:government_id/cities/edit/:city_id", label: 'Government :government_id Citiy :city_id Edit' },
    { url: "/users/governments/:government_id/cities/create", label: 'Government :government_id Cities Create' },
    { url: "/users/governments/:government_id/cities/:city_id/areas", label: 'Government :government_id Cities :city_id Areas' },
    { url: "/users/governments/:government_id/cities/:city_id/areas/edit/:area_id", label: 'Government :government_id Citiy :city_id Area :area_id Edit' },
    { url: "/users/governments/:government_id/cities/:city_id/areas/create", label: 'Government :government_id Cities :city_id Area Create' },

    { url: "/users/agencies/create", label: 'Agencies Create' },
    { url: "/users/agencies/edit/:id", label: 'Agencies :id Edit' },
    { url: "/users/agencies", label: 'Agencies' },
    { url: "/users/driver_license_types/create", label: 'Driver License Types Create' },
    { url: "/users/driver_license_types/edit/:id", label: 'Driver License Types :id Edit' },
    { url: "/users/driver_license_types", label: 'Driver License Types' },
    { url: "/users/drivers/create", label: 'Drivers Create' },
    { url: "/users/drivers/edit/:id", label: 'Drivers :id Edit' },
    { url: "/users/drivers", label: 'Drivers' },
    { url: "/users/vehicle_models", label: 'Vehicle Models' },
    { url: "/users/vehicle_models/create", label: 'Vehicle Models Create' },
    { url: "/users/vehicle_models/edit/:id", label: 'Vehicle Models :id Edit' },
    { url: "/users/vehicle_models/:parent_id/sub", label: 'Vehicle Models :parent_id SUb' },
    { url: "/users/vehicle_models/:parent_id/sub/create", label: 'Vehicle Models :parent_id SUb Create' },
    { url: "/users/vehicle_models/:parent_id/sub/edit/:id", label: 'Vehicle Models :parent_id SUb Edit :id' },
    { url: "/users/vehicle_colors/create", label: 'Vehicle Colors Create' },
    { url: "/users/vehicle_colors/edit/:id", label: 'Vehicle Colors :id Edit' },
    { url: "/users/vehicle_colors", label: 'Vehicle Colors' },
    { url: "/users/vehicle_types/create", label: 'Vehicle Types Create' },
    { url: "/users/vehicle_types/edit/:id", label: 'Vehicle Types :id Edit' },
    { url: "/users/vehicle_types", label: 'Vehicle Types' },
    { url: "/users/vehicle_capacities/create", label: 'Vehicle Capacities Create' },
    { url: "/users/vehicle_capacities/edit/:id", label: 'Vehicle Capacities :id Edit' },
    { url: "/users/vehicle_capacities", label: 'Vehicle Capacities' },
    { url: "/users/vehicle_fuel_types/create", label: 'Vehicle Fuel Types Create' },
    { url: "/users/vehicle_fuel_types/edit/:id", label: 'Vehicle Fuel Types :id Edit' },
    { url: "/users/vehicle_fuel_types", label: 'Vehicle Fuel Types' },

    { url: "/users/network_operators/create", label: 'Network Operators Create' },
    { url: "/users/network_operators/edit/:id", label: 'Network Operators :id Edit' },
    { url: "/users/network_operators", label: 'Network Operators' },

    { url: "/users/tracking_devices/create", label: 'Tracking Devices Create' },
    { url: "/users/tracking_devices/edit/:id", label: 'Tracking Devices :id Edit' },
    { url: "/users/tracking_devices", label: 'Tracking Devices' },

    { url: "/users/vehicles/create", label: 'Vehicles Create' },
    { url: "/users/vehicles/edit/:id", label: 'Vehicles :id Edit' },
    { url: "/users/vehicles", label: 'Vehicles' },

    { url: "/users/assistants/create", label: 'Assistants Create' },
    { url: "/users/assistants/edit/:id", label: 'Assistants :id Edit' },
    { url: "/users/assistants", label: 'Assistants' },

    { url: "/users/supervisors/create", label: 'Supervisors Create' },
    { url: "/users/supervisors/edit/:id", label: 'Supervisors :id Edit' },
    { url: "/users/supervisors", label: 'Supervisors' },

    { url: "/users/schools/create", label: 'Schools Create' },
    { url: "/users/schools/edit/:id", label: 'Schools :id Edit' },
    { url: "/users/schools", label: 'Schools' },

    { url: "/users/education_periods/create", label: 'Education Period Create' },
    { url: "/users/education_periods/edit/:id", label: 'Education Period :id Edit' },
    { url: "/users/education_periods", label: 'Education Periods ' },

    { url: "/users/passengers/create", label: 'Passenger Create' },
    { url: "/users/passengers/edit/:id", label: 'Passenger :id Edit' },
    { url: "/users/passengers", label: 'Passengers' },
    { url: "/users/passengers/:passenger_id/students", label: 'Passenger :passenger_id Student' },
    { url: "/users/passengers/:passenger_id/students/edit/:student_id", label: 'Passenger :passenger_id Student :student_id Edit' },
    { url: "/users/passengers/:passenger_id/students/create", label: 'Passenger :passenger_id Students Create' },

    { url: "/users/students", label: 'Students' },

    { url: "/users/requests/trips", label: 'Requests Trips' },
    { url: "/users/requests/drivers", label: 'Requests Drivers' },

    { url: "/users/packages/create", label: 'Driver Package Create' },
    { url: "/users/packages/edit/:id", label: 'Driver Package :id Edit' },
    { url: "/users/packages", label: 'Driver Packages' },

    { url: "/users/trip_packages/create", label: 'Trip Package Create' },
    { url: "/users/trip_packages/edit/:id", label: 'Trip Package :id Edit' },
    { url: "/users/trip_packages", label: 'Trip Packages' },

    { url: "/users/trips/create", label: 'Trip Create' },

    { url: "/users/trips/edit/:id", label: 'Trip :id Edit' },
    { url: "/users/trips", label: 'Trips' },
    { url: "/users/trips/:trip_id/students", label: 'Trip :trip_id Student' },
    { url: "/users/trips/:trip_id/students/edit/:student_id", label: 'Trip :trip_id Student :student_id Edit' },
    { url: "/users/trips/:trip_id/students/create", label: 'Trip :trip_id Students Create' },
    { url: "/users/trips/:trip_id/sub", label: 'Trip :sub_id Sub' },
    { url: "/users/trips/:trip_id/sub/edit/:student_id", label: 'Trip :trip_id Sub :student_id Edit' },
    { url: "/users/trips/:trip_id/sub/tracking_live/:id", label: 'Trip :trip_id Sub :id Tracking Live' },
    { url: "/users/trips/:trip_id/sub/create", label: 'Trip :trip_id Sub Create' },


    { url: "/users/fixed_trips/create", label: 'Fixed Trip Create' },

    { url: "/users/fixed_trips/edit/:id", label: 'Fixed Trip :id Edit' },
    { url: "/users/fixed_trips", label: 'Fixed Trips' },
    


    { url: "/users/reports/trips/tracking_live", label: 'Report Trip Tracking Live' },

    { url: "/users/roles/create", label: 'User Role Create' },
    { url: "/users/roles/edit/:id", label: 'User Role :id Edit' },
    { url: "/users/roles/permissions/:id", label: 'User Role :id Edit Permiissions' },
    { url: "/users/roles", label: 'User Roles' },

    { url: "/users/users/create", label: 'Dashboard Users Create' },
    { url: "/users/users/edit/:id", label: 'Dashboard Users :id Edit' },
    { url: "/users/users", label: 'Dashboard Users' },

    { url: "/users/complaints", label: 'Dashboard Complaints' },
    { url: "/users/trips_subs", label: 'Dashboard Trips Subs' },

    { url: "/users/request_status/create", label: 'Request Statuses Create' },
    { url: "/users/request_status/edit/:id", label: 'Request Statuses :id Edit' },
    { url: "/users/request_status", label: 'Request Statuses' },
  ];
  routes_school_quard = [
    { url: "/dashboard/school/dashboards", label: "Dashboards" },
    { url: "/dashboard/school/my_account/profile", label: "My Account" },
    { url: "/dashboard/school/trips", label: "Trips" },
    { url: "/dashboard/school/trips/create", label: "Trips Create" },
    { url: "/dashboard/school/trips/edit/:id", label: "Trips :id Edit" },
    { url: "/dashboard/school/trips/:id/students", label: "Trips :id Students" },
    { url: "/dashboard/school/trips/:id/students/create", label: "Trips :id Students Create" },
    { url: "/dashboard/school/trips/:id/students/edit/:st_id", label: "Trips :id Students Edit :st_id" },
    { url: "/dashboard/school/trips/:id/students/presence", label: "Trips :id Students Presence" },
    // { url: "/dashboard/school/trips/:id/students/sub", label: "Trips :id Students Sub" },

    { url: "/dashboard/school/trips/:trip_id/students", label: 'Trip :trip_id Student' },
    { url: "/dashboard/school/trips/:trip_id/sub", label: 'Trip :trip_id Sub' },
    { url: "/dashboard/school/trips/:trip_id/sub/create", label: 'Trip :trip_id Sub Create' },
    { url: "/dashboard/school/trips/:trip_id/sub/edit/:id", label: 'Trip :trip_id Sub Edit :id' },
    { url: "/dashboard/school/trips/:trip_id/sub/tracking_live/:id", label: 'Trip :trip_id Sub :id Tracking Live' },
    { url: "/dashboard/school/trips/:trip_id/sub/images_live/:id", label: 'Trip :trip_id Sub :id Images Live' },
    { url: "/dashboard/school/trips/:trip_id/sub/logs/:id", label: 'Trip :trip_id Sub :id Log' },
    { url: "/dashboard/school/trips/:trip_id/presence", label: 'Trip :trip_id Presence' },
    { url: "/dashboard/school/reports/trips/tracking_live", label: 'Report Trips Tracking Live' },
    { url: "/dashboard/school/requests/passengers", label: "Passenger Requests" },
    { url: "/dashboard/school/vehicles", label: "Vehicles" },
    { url: "/dashboard/school/vehicles/create", label: "Vehicles Create" },
    { url: "/dashboard/school/vehicles/edit/:id", label: 'Vehicles :id Edit' },
    { url: "/dashboard/school/drivers", label: "Drivers" },
    { url: "/dashboard/school/drivers/create", label: "Drivers Create" },
    { url: "/dashboard/school/drivers/edit/:id", label: 'Drivers :id Edit' },
    { url: "/dashboard/school/drivers/:id/tokens", label: 'Drivers :id Tokens' },
    { url: "/dashboard/school/passengers", label: "Passengers" },
    { url: "/dashboard/school/passengers/create", label: "Passengers Create" },
    { url: "/dashboard/school/passengers/edit/:id", label: 'Passengers :id Edit' },
    { url: "/dashboard/school/passengers/:id/tokens", label: 'Passengers :id Tokens' },
    { url: "/dashboard/school/passengers/:id/students", label: 'Passengers :id Students' },
    { url: "/dashboard/school/passengers/:id/students/create", label: 'Passengers :id Students Create' },
    { url: "/dashboard/school/passengers/:id/students/edit/:id_student", label: 'Passengers :id Students Edit :id_student' },




  ];

  active_history_routes = [];
  constructor(
    private router: Router,
    private mainService: MainService,
    private authService: AuthenticationService,
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (authService.user) {
        if (event.url == '/')
          this.router.navigateByUrl(authService.quard + "/dashboards");
        console.log(this.authService.quard);
        if (this.authService.quard == "user") {
          console.log("problem");
          this.validate_auth(event.url)
          this.mainService.getLanguages();
        }
        if (this.authService.quard == "school") {
          console.log("problem2");
          this.validate_auth_school(event.url)
        }
        console.log(event.url);
      }
    });
  }

  validate_auth_school(url) {
    //validate ids in url
    console.log("problem2 1");
    let found_url = null;
    this.routes_school_quard.forEach(element => {
      var route_element_split = element.url.split("/");
      var url_split = url.split("/");
      if (route_element_split.length == url_split.length) {
        var ids = [];
        var not_found = false;
        for (let index = 0; index < route_element_split.length; index++) {
          if (route_element_split[index] != url_split[index]) {
            if (!route_element_split[index].includes(":")) {
              not_found = true;
            }
            else {
              ids.push({ key: route_element_split[index], value: url_split[index] });
            }
          }
        }
        if (!not_found) {
          found_url = element.url;
          return;
        }
      }
    });
    console.log("problem2 2");
    console.log(found_url);
    console.log(url);
    if (found_url) {

      this.active_history_routes_add_school(url);
    }
    else {
      // console.log(this.authService.user.permissions);
      this.router.navigateByUrl("school/dashboards");
    }
  }
  validate_auth(url) {
    //validate ids in url
    let found_url = null;
    this.routes.forEach(element => {
      var route_element_split = element.url.split("/");
      var url_split = url.split("/");
      if (route_element_split.length == url_split.length) {
        var ids = [];
        var not_found = false;
        for (let index = 0; index < route_element_split.length; index++) {
          if (route_element_split[index] != url_split[index]) {
            if (!route_element_split[index].includes(":")) {
              not_found = true;
            }
            else {
              ids.push({ key: route_element_split[index], value: url_split[index] });
            }
          }
        }
        if (!not_found) {
          found_url = element.url;
          return;
        }
      }
    });
    if (this.authService.user && this.authService.user.permissions.includes(found_url)) {
      this.active_history_routes_add(url);
    }
    else {
      // console.log(this.authService.user.permissions);
      this.router.navigateByUrl("dashboard/users/dashboards");
    }
  }

  active_history_routes_add(url) {
    this.active_route = url;
    let found = false;
    this.active_history_routes.forEach(element => {
      if (element.url == url) {
        this.active_route = element;
        found = true;
        return;
      }
    });
    if (found)
      return;
    //validate ids in url
    this.routes.forEach(element => {
      var route_element_split = element.url.split("/");
      var url_split = url.split("/");
      if (route_element_split.length == url_split.length) {
        var ids = [];
        var not_found = false;
        for (let index = 0; index < route_element_split.length; index++) {
          if (route_element_split[index] != url_split[index]) {
            if (!route_element_split[index].includes(":")) {
              not_found = true;
            }
            else {
              ids.push({ key: route_element_split[index], value: url_split[index] });
            }
          }
        }
        if (!not_found) {
          var label = element.label;
          ids.forEach(id => {
            label = label.replace(id.key, id.value);
          });
          let el = { url: url, label: label };
          this.active_route = el;
          this.active_history_routes.push(el);
          this.removeMoreThanLimit();
          return;
        }
      }
    });

  }
  active_history_routes_add_school(url) {
    this.active_route = url;
    let found = false;
    this.active_history_routes.forEach(element => {
      if (element.url == url) {
        this.active_route = element;
        found = true;
        return;
      }
    });
    if (found)
      return;
    //validate ids in url
    this.routes_school_quard.forEach(element => {
      var route_element_split = element.url.split("/");
      var url_split = url.split("/");
      if (route_element_split.length == url_split.length) {
        var ids = [];
        var not_found = false;
        for (let index = 0; index < route_element_split.length; index++) {
          if (route_element_split[index] != url_split[index]) {
            if (!route_element_split[index].includes(":")) {
              not_found = true;
            }
            else {
              ids.push({ key: route_element_split[index], value: url_split[index] });
            }
          }
        }
        if (!not_found) {
          var label = element.label;
          ids.forEach(id => {
            label = label.replace(id.key, id.value);
          });
          let el = { url: url, label: label };
          this.active_route = el;
          this.active_history_routes.push(el);
          this.removeMoreThanLimit();
          return;
        }
      }
    });

  }

  removeMoreThanLimit() {
    if (this.active_history_routes.length > this.max) {
      // this.active_history_routes.splice(0, 1);
    }
  }
  close(item) {
    let index = this.active_history_routes.indexOf(item);
    if (this.active_history_routes.length == 1) {
      this.active_history_routes.splice(index, 1);
    }
    else if (this.active_route == item) {
      this.active_route = this.active_history_routes[this.active_history_routes.length - 1];
      this.active_history_routes.splice(index, 1);
      this.router.navigateByUrl(this.active_history_routes[this.active_history_routes.length - 1].url);
    }
    else {
      this.active_history_routes.splice(index, 1);
    }
  }
  ngOnInit() {
  }

}
