import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DriversRoutingModule } from './drivers-routing.module';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { IndexComponent } from './index/index.component';
import { NgbPaginationModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
@NgModule({
  declarations: [IndexComponent, CreateUpdateComponent],
  imports: [
    CommonModule,
    DriversRoutingModule,
    NgbPaginationModule,
    UiSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    MomentModule
  ]
})
export class DriversModule { }
