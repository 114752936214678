import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/core/services/main.service';
import { ServiceService } from '../service.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { formObject } from 'src/app/pages/helper/formObject';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  object = null;
  filter = { page: 1, phone: '', sortBy: 'Newst', version_code: 'all', license_expiry_date_from_date: "", license_expiry_date_to_date: '', };
  page = 1;
  pageSize = 0;
  total = 0;
  // loader_search = false;
  // search_phone = "";
  // sortBy = "Newst";
  // version_code = "all";
  appVersionCodesList = [];

  objectForm = new formObject;


  constructor(
    private mainService: MainService,
    private service: ServiceService,
    private authService: AuthenticationService,
    public formBuilder: FormBuilder,
  ) {
    this.mainService.changeBreadCrumbItems([
      { label: 'Drivers', path: '', active: true },
    ]);
    this.mainService.setTitle("Drivers");
    this.getAppVersionCodes();

    this.getIndexData();
  }

  ngOnInit() {
    this.objectForm.formGroup = this.formBuilder.group({
      phone: [''],
      license_expiry_date_from_date: [''],
      license_expiry_date_to_date: [''],
      version_code: [''],
      sortBy: ["Newst"]
    });


    this.objectForm.submitAction = function (parent) {
      if (this.formGroup.value.license_expiry_date_from_date) {
        parent.filter.license_expiry_date_from_date = this.formGroup.value.license_expiry_date_from_date.year + '-' + this.formGroup.value.license_expiry_date_from_date.month + '-' + this.formGroup.value.license_expiry_date_from_date.day;
      }
      if (this.formGroup.value.license_expiry_date_to_date) {
        parent.filter.license_expiry_date_to_date = this.formGroup.value.license_expiry_date_to_date.year + '-' + this.formGroup.value.license_expiry_date_to_date.month + '-' + this.formGroup.value.license_expiry_date_to_date.day;
      }

      if (this.formGroup.value.sortBy) {
        parent.filter.sortBy = this.formGroup.value.sortBy;
      }
      parent.filter.sortBy = this.formGroup.value.sortBy;

      parent.filter.version_code = this.formGroup.value.version_code;
      parent.filter.page = 1;
      this.submit = true;
      this.loader = true;
      parent.getIndexData();
    };
  }

  getAppVersionCodes() {
    this.service.getAppVersionCodes().subscribe((data: any) => {
      this.appVersionCodesList = data.data;
    });
  }

  getOnlineStatus(date1) {
    let currentDate = new Date();
    let date = new Date(date1);
    return ((Math.ceil( (currentDate.getTime() - date.getTime() ) /1000/60 )) > 3)?0:1;
    // return "currentDate.getTime()";
  }

  getIndexData() {
    this.service.getAll(this.filter).subscribe((data: any) => {
      data.data.data.forEach(element => {
        element.online = this.getOnlineStatus(element.updated_at);
      });;
      this.object = data.data;
      this.total = data.data.total;
      this.pageSize = data.data.per_page;
      // this.loader_search = false;
      this.objectForm.loader = false;
      this.objectForm.submit = false;
    });
  }

  pageChange(page: number) {
    this.filter.page = page;
    this.getIndexData();
  }

  deleteObject(id) {
    this.service.deleteObject(id).subscribe((data: any) => {
      if (data.status == 1) {
        this.mainService.sweetAlertMessage("Deleted!", 'Your file has been deleted.');
        this.mainService.show_notifications("Successfully Deleted");
      }
      if (data.status == 0) {
        this.mainService.sweetAlertMessage("Deleted!", "Your file has't been deleted.", 'error');
        this.mainService.showFormErrors(data.errors);
      }
      this.getIndexData();
    });
  }

  // form_search(value, key) {
  //   this[key] = value;
  //   // this.filter.page = 1;
  //   // this.filter[key] = value;
  //   // this.getIndexData();
  // }

  // onclick() {
  //   this.filter.phone = this.search_phone;
  //   this.filter.sortBy = this.sortBy;
  //   this.filter.page = 1;
  //   this.loader_search = true;
  //   this.filter.version_code = this.version_code;
  //   this.getIndexData();
  // }

}
