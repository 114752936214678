import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationsService } from 'angular2-notifications';
import Swal, { SweetAlertType } from 'sweetalert2';
import { Title } from '@angular/platform-browser';
declare var jQuery: any;
@Injectable({ providedIn: 'root' })
export class MainService {

    languages = [];

    breadCrumbItemss: Array<{}>;
    page_count = 25;

    change_page_count_list = [
        25,
        50,
        100,
        150,
        200,
        300,
        400,
        500,
        750,
        1000
    ];
    chekedIdsToDelete = [];


    //apiUrl = "http://localhost/Cars/api/";
    // apiUrl = "http://tracking.morecreativeltd.com/cars_backend/cars_backend/api/";
    // apiUrl = "https://api.schoolz.io/api/";
    // apiUrl = "https://apidev.schoolz.io/api/";
    // apiUrlOrigin = "car_api.test/api/";//local
    // apiUrlOrigin = "api.schoolz.io/api/";//online dev
    // apiUrlOrigin = "apidev.schoolz.io/api/";//online master
    apiUrlOrigin = "schoolzsaas.com/api/";//online saas
    apiUrl = "";
    apiHttps = "https://";

    invoiceStatusList = [
        {
            id: 0,
            name: 'Draft',
        },
        {
            id: 1,
            name: 'Pending',
        },
        {
            id: 2,
            name: 'Processing',
        },
        {
            id: 3,
            name: 'Paid',
        },
        {
            id: 4,
            name: 'Canceled',
        },
    ];

    paymentMethodList = [
        {
            id: 0,
            name: 'Collect In Our Office',
        },
        {
            id: 1,
            name: 'Smart wallet',
        },
        {
            id: 2,
            name: 'Bank Deposit / Wire Transfer',
        },
        {
            id: 3,
            name: 'Credit Card',
        },
        {
            id: 4,
            name: 'Fawry',
        },
        {
            id: 5,
            name: 'ValU',
        },
        {
            id: 6,
            name: 'Aman',
        },
        {
            id: 7,
            name: 'NBE Installments',
        }
    ];


    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router,
        private _snackBar: MatSnackBar,
        private ngxService: NgxUiLoaderService,
        private _notifications: NotificationsService,
        private titleService: Title,

    ) {
        this.changeApiUrlForSubDomain();
        // this.getLanguages();
        this.breadCrumbItemss = [
            { label: 'Dashboard', path: '/dashboards', active: true },
        ];
    }

    changeApiUrlForSubDomain() {
        var host = window.location.host.split('.');
        var subdomain = "";

        if (host.length > 2 && host[0] != "app") {
            subdomain = host[0] + '.';

        }
        this.apiUrl = this.apiHttps + subdomain + this.apiUrlOrigin;
        this.apiUrlOrigin = this.apiHttps + this.apiUrlOrigin;
        console.log(this.apiUrl);
        console.log(this.apiUrlOrigin);
        console.log(subdomain);
    }

    checkboxAll(object) {
        if (this.chekedIdsToDelete.length == object.data.length) {
            this.chekedIdsToDelete = [];
        }
        else {
            this.chekedIdsToDelete = [];
            object.data.forEach(element => {
                this.chekedIdsToDelete.push(element.id);
            });
        }
    }

    checkChange(id) {
        const _index = this.chekedIdsToDelete.indexOf(id);
        if (_index > -1) {
            this.chekedIdsToDelete.splice(_index, 1);
        }
        else {
            this.chekedIdsToDelete.push(id);
        }
    }

    deleteAllSelectedDialowq(parent_class) {
        if (this.chekedIdsToDelete.length > 0) {
            this.sweetAlert(parent_class, '', 'Are you sure?', 'You wont be able to revert this!', 'deleteAllSelected', 'warning');
        }
    }

    toDateNgbDatepicker(dob) {
        if (dob) {
            const [year, month, day] = dob.split('-');
            const obj = {
                year: parseInt(year), month: parseInt(month), day:
                    parseInt(day.split(' ')[0].trim())
            };
            return obj;
        }
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle("Dashboard Admin User " + newTitle);
    }


    getLanguages_() {
        return this.http.get(this.apiUrlOrigin + 'user/languages/getAll');
    }

    getLanguages() {
        this.getLanguages_().subscribe((data: any) => {
            this.languages = data.objects;
            // console.log(this.languages);
        });
    }

    loadDropify(image = "", class_name = "") {
        (function ($) {
            $(document).ready(function () {
                var defaultFile = {};
                if (image)
                    defaultFile = {
                        defaultFile: image,

                    };
                if (class_name)
                    class_name = "." + class_name;
                this.imageView = $('.dropify' + class_name).dropify(defaultFile);
            });
        })(jQuery);
    }

    loadGalleryZoom() {
        (function ($) {
            $(document).ready(function () {
                $('.popup-gallery').magnificPopup({
                    delegate: '.a-img-track',
                    type: 'image',
                    tLoading: 'Loading image #%curr%...',
                    mainClass: 'mfp-img-mobile',
                    gallery: {
                        enabled: true,
                        navigateByImgClick: true,
                        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                    },
                    image: {
                        tError: '<a href="%url%">The image #%curr%</a> could not be loaded',
                        titleSrc: function (item) {
                            return item.el.attr('title');
                        }
                    }
                });
            });
        })(jQuery);
    }
    changeBreadCrumbItems(breadCrumbItemss = []) {
        // this.breadCrumbItemss.
        var quard = JSON.parse(this.cookieService.getCookie('currentQuard'));
        console.log(quard);
        this.breadCrumbItemss = breadCrumbItemss.length ? [
            { label: 'Dashboard', path: '/' + (quard ? quard : 'users') + '/dashboards', active: false },
        ] : [
            { label: 'Dashboard', path: '', active: true },
        ];
        breadCrumbItemss.forEach(element => {
            this.breadCrumbItemss.push(element);
        });
    }


    openLoaderFull() {
        this.ngxService.start();
    }

    openRoute(route) {
        this.router.navigateByUrl(route)
    }
    show_notifications(title, alert = 'success', duration = 5000) {
        let config = {
            timeOut: duration,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
            clickIconToClose: true,
            animate: 'scale',
        };

        switch (alert) {
            case "success":
                this._notifications.success(title, '', config);
                break;
            case "danger":
                this._notifications.error(title, '', config);
                break;
        }

        // var panelClass = ['text-white'];
        // switch (alert) {
        //     case "success":
        //         panelClass.push('bg-success');
        //         break;
        //     case "danger":
        //         panelClass.push('bg-danger');
        //         break;
        // }
        // this._snackBar.open(title, 'Close', {
        //     // duration: 5000,
        //     horizontalPosition: ('right'),
        //     panelClass: panelClass
        // });
        // this._snackBar.open(title, 'Close', {
        //     // duration: 5000,
        //     horizontalPosition: ('right'),
        //     panelClass: panelClass
        // });
    }

    public getGovernmentsWithCities() {
        let url = "user/governments/get_all_with_cities";
        return this.http.get(this.apiUrl + url);

    }

    public getGeneralGovernmentsWithCitiesAreas() {
        let url = "general/governments/get_all_with_cities_areas";
        return this.http.get(this.apiUrl + url);

    }

    public getGovernmentsWithCitiesSchool() {
        let url = "school/governments/get_all_with_cities";
        return this.http.get(this.apiUrl + url);

    }

    public userChangePassword(object) {
        let url = "user/changePassword";
        return this.http.post(this.apiUrl + url, object);
    }

    public updateProfile(object) {
        let url = "user/updateProfile";
        return this.http.post(this.apiUrl + url, object);
    }



    showFormErrors(errors) {
        Object.keys(errors).forEach(element => {
            errors[element].forEach(elementt => {
                this.show_notifications(elementt, 'danger');
            });
        });
    }

    sweetAlertMessage(title, message, type: SweetAlertType = "success") {
        Swal.fire(title, message, type);

    }

    sweetAlert(parent, id, title, message, method_after_delete, type: SweetAlertType = "success") {
        Swal.fire({
            title: title,
            text: message,
            type: type,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                parent[method_after_delete](id);
                // this.sweetAlertMessage("Deleted!", 'Your file has been deleted.', 'error');
                // Swal.fire('Deleted!', 'Your file has been deleted.', 'error');
            }
        });
    }


    sweetAlertDelete(parent, id, method_after_delete = 'deleteObject') {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                parent[method_after_delete](id);
                // this.sweetAlertMessage("Deleted!", 'Your file has been deleted.', 'error');
                // Swal.fire('Deleted!', 'Your file has been deleted.', 'error');
            }
        });
    }

    sweetAlertEndTrip(parent, id, method_after_delete = 'endObject') {
        Swal.fire({
            title: 'Are you sure to end trip?',
            text: 'You won\'t be able to revert this!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, End it!'
        }).then((result) => {
            if (result.value) {
                parent[method_after_delete](id);
            }
        });
    }

    change_page_count_lists(count, controller, function_name) {
        this.page_count = count;
        console.log(typeof controller[function_name]);
        // console.log(controller.hasOwnProperty(getIndexData);
        if (typeof controller[function_name] === "function") {
            (controller[function_name]());
            console.log("Yes");
        }
        else {
            console.log("No");
        }
    }

}

