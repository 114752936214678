import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/core/services/main.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    private http: HttpClient,
    private mainService: MainService,
  ) { }

  public createUpdate(object, id = "") {
    let url = "user/drivers/create_update";
    if (id)
      url += '/' + id;
    console.log(id);
    return this.http.post(this.mainService.apiUrl + url, object);
  }


  public getObject(id) {
    let url = "user/drivers/get/" + id;
    return this.http.get(this.mainService.apiUrl + url);
  }

  public getAll(object) {
    let url = "user/drivers?page=" + object.page + "&phone=" + object.phone + "&sortBy=" + object.sortBy + "&version_code=" + object.version_code+ "&license_expiry_date_to_date=" + object.license_expiry_date_to_date+ "&license_expiry_date_from_date=" + object.license_expiry_date_from_date;
    return this.http.get(this.mainService.apiUrl + url);
  }

  public getDriverLicenseTypes() {
    let url = "user/driver_license_types/getAll";
    return this.http.get(this.mainService.apiUrl + url);
  }

  public getAgencies() {
    let url = "user/agencies/getAll";
    return this.http.get(this.mainService.apiUrl + url);
  }

  public getSchoolz() {
    let url = "user/schools/getAll";
    return this.http.get(this.mainService.apiUrl + url);
  }

  public getAppVersionCodes() {
    let url = "user/tokens/app_version_codes?model=drivers";
    return this.http.get(this.mainService.apiUrl + url);
  }

  public getPackages() {
    let url = "user/packages/getAll";
    return this.http.get(this.mainService.apiUrl + url);
  }


  public getVehicles() {
    let url = "user/vehicles/getAll";
    return this.http.get(this.mainService.apiUrl + url);
  }

  public deleteObject(id) {
    let url = "user/drivers/delete/" + id;
    return this.http.get(this.mainService.apiUrl + url);
  }


}
