import { Component } from '@angular/core';
import { MessagingService } from './service/messaging.service';
@Component({
  selector: 'app-ubold',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'push-notification';
  message;
  constructor(private messagingService: MessagingService) { 

  }
  ngOnInit() {
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    // this.messagingService.receiveMessaging()
    // this.message = this.messagingService.currentMessage
  }
}
