import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TripsRoutingModule } from './trips-routing.module';
import { TrackingLiveComponent } from './tracking-live/tracking-live.component';
import { AgmCoreModule } from '@agm/core';
import { GenralKeys } from 'src/app/keys/GenralKeys';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    TrackingLiveComponent
  ],
  imports: [
    CommonModule,
    TripsRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: GenralKeys.googleMapApiKey
    })
  ]
})
export class TripsModule { }
