import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  team = [
    {
      img: 'assets/images/home/team.png',
      name: 'Brooklyn Simmons',
      position: 'Founder & CEO'
    },
    {
      img: 'assets/images/home/team.png',
      name: 'Brooklyn Simmons',
      position: 'Founder & CEO'
    },
    {
      img: 'assets/images/home/team.png',
      name: 'Brooklyn Simmons',
      position: 'Founder & CEO'
    },
    {
      img: 'assets/images/home/team.png',
      name: 'Brooklyn Simmons',
      position: 'Founder & CEO'
    }
  ]

  faqs = [
    {
      id: 1,
      title: 'What is Routz?',
      content: 'Routz is a powerful SaaS platform that provides schools and businesses with a comprehensive solution for managing their fleet of vehicles.'
    },
    {
      id: 2,
      title: 'What features does Routz offer?',
      content: 'Routz is a powerful SaaS platform that provides schools and businesses with a comprehensive solution for managing their fleet of vehicles.'
    },
    {
      id: 3,
      title: 'How can Routz benefit businesses and schools?',
      content: 'Routz is a powerful SaaS platform that provides schools and businesses with a comprehensive solution for managing their fleet of vehicles.'
    },
    {
      id: 4,
      title: 'What pricing plans does Routz offer?',
      content: 'Routz is a powerful SaaS platform that provides schools and businesses with a comprehensive solution for managing their fleet of vehicles.'
    },
    {
      id: 5,
      title: 'How is Routz deployed?',
      content: 'Routz is a powerful SaaS platform that provides schools and businesses with a comprehensive solution for managing their fleet of vehicles.'
    },
  ]
  activeIndex: number = 0;

  constructor() { }

  ngOnInit() {
  }

  togglePanel(index: number): void {
    this.activeIndex = this.activeIndex === index ? -1 : index;
  }

}
