import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delete-my-account',
  templateUrl: './delete-my-account.component.html',
  styleUrls: ['./delete-my-account.component.scss']
})
export class DeleteMyAccountComponent {
  accountDeletionForm: FormGroup;
  submitted = false;
  successMessage: string | null = null;

  constructor(private formBuilder: FormBuilder) {
    this.accountDeletionForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      emailAddress: ['', [Validators.required, Validators.email]],
      removalReason: [''],
      confirmDeletion: [false, Validators.requiredTrue]
    });
  }

  // Convenience getter for easy access to form fields
  get f() { return this.accountDeletionForm.controls; }

  onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.accountDeletionForm.invalid) {
      return;
    }

    // Process your form submission here (e.g., send an HTTP request)
    // Example:
    // this.yourService.submitAccountDeletionRequest(this.accountDeletionForm.value)
    //   .subscribe(response => {
    //     this.successMessage = 'Your account deletion request has been received. Please check your email for further instructions.';
    //   });

    this.successMessage = 'Your account deletion request has been received. Please check your email for further instructions.';
  }


}
