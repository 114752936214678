import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CitiesRoutingModule } from './cities-routing.module';
import { IndexComponent } from './index/index.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CreateUpdateComponent } from './create-update/create-update.component';

@NgModule({
  declarations: [IndexComponent,CreateUpdateComponent],
  imports: [
    CommonModule,
    CitiesRoutingModule,
    NgbPaginationModule,
    UiSwitchModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class CitiesModule { }
