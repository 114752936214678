import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { MainService } from '../services/main.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardDomain implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private mainService: MainService,

    ) {

    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var host = window.location.host.split('.');
        if (host.length == 2) {
            return true;
        }
        var subdomain = host[0];
        if(subdomain=="app"){
            return true;
        }
        return this.authenticationService.checkSubDomain(subdomain).map((res: any) => {
            this.authenticationService.setSubDomain({
                key: subdomain,
                name: res.object,
            });
            if (res.object) return true;
            this.router.navigate(['/error/404']);
            return false;
        });

    }
}