import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { IndexComponent } from './index/index.component';
const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'create',
    component: CreateUpdateComponent
  },
  {
    path: 'edit/:id',
    component: CreateUpdateComponent
  },
  {
    path: ':driver_id/tokens',
    loadChildren: () => import('./tokens/tokens.module').then(m => m.TokenstsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DriversRoutingModule { }
