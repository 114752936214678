import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CookieService } from '../services/cookie.service';
import { User } from '../models/auth.models';
import { Router } from '@angular/router';
import { MainService } from './main.service';
import 'rxjs/add/operator/map'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    user = null;
    sub_domain = {
        key: "",
        name: "",
    };



    quard = null;
    lang = "en";

    constructor(private http: HttpClient,
        private cookieService: CookieService,
        public mainService: MainService,
        private router: Router,
    ) {
        this.updateProfile();
    }


    /**
     * Returns the current user
     */
    public currentUser(): User {
        // console.log(document.cookie);
        if (!this.user) {
            if (this.cookieService.getCookie('currentUser') && this.cookieService.getCookie('currentQuard')) {
                this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
                this.quard = JSON.parse(this.cookieService.getCookie('currentQuard'));
                this.updateProfile();
            }
        }
        return this.user;
    }

    checkSubDomain(subdomain): Observable<any> {
        return this.http.get(this.mainService.apiUrlOrigin + "user/school/checkSubDomain?sub_domain=" + subdomain);
    }

    public checkSubDomain_(subdomain) {
        // console.log(document.cookie);
        return this.http.get(this.mainService.apiUrlOrigin + "user/school/checkSubDomain?sub_domain=" + subdomain);
        // return await this.http.get(this.mainService.apiUrlOrigin + "user/school/checkSubDomain?sub_domain=" + subdomain, {}).subscribe((data: any) => {
        //     console.log(data);
        //     console.log("Monem3");
        //     return {
        //         key: subdomain,
        //         name: data.object
        //     };
        //     // this.setSubDomain({
        //     //     key: subdomain,
        //     //     name: data.object
        //     // });
        // });
        // if (this.sub_domain.name == "") {
        //     if (this.cookieService.getCookie('subDomain')) {
        //         console.log("Monem1");
        //         // this.sub_domain = JSON.parse(this.cookieService.getCookie('subDomain'));
        //         // console.log(this.sub_domain);
        //         // this.updateSubDomain();
        //     }
        //     else {
        //         console.log("Monem2");

        //         this.http.get(this.mainService.apiUrlOrigin + "user/school/checkSubDomain?sub_domain=" + subdomain, {}).subscribe((data: any) => {
        //             console.log(data);
        //             console.log("Monem3");

        //             // this.setSubDomain({
        //             //     key: subdomain,
        //             //     name: data.object
        //             // });
        //         });
        //     }

        // }
        // console.log(this.sub_domain);
        // return this.sub_domain;
    }

    public updateProfile() {
        if (this.user) {
            if (this.quard == "user") {
                this.http.get(this.mainService.apiUrl + "user/getUserData", {}).subscribe((data: any) => {
                    let user = data.object;
                    user.token = this.user.token;
                    this.setUser(user, this.quard);
                });
            } else if (this.quard == "school") {
                this.http.get(this.mainService.apiUrl + "school/getUserData", {}).subscribe((data: any) => {
                    let user = data.object;
                    user.token = this.user.token;
                    this.setUser(user, this.quard);
                });
            }

        }
    }

    public updateSubDomain() {
        if (this.sub_domain.key && this.sub_domain.name) {
            this.http.get(this.mainService.apiUrlOrigin + "user/school/checkSubDomain?sub_domain=" + this.sub_domain.key, {}).subscribe((data: any) => {
                this.setSubDomain({
                    key: this.sub_domain.key,
                    name: data.object
                });
            });

        }
    }
    /**
     * Set the current user
     */
    public setUser(user, quard = "users") {
        this.user = user;
        this.quard = quard;
        this.cookieService.setCookie('currentUser', JSON.stringify(user), 10);
        this.cookieService.setCookie('currentQuard', JSON.stringify(quard), 10);
        // console.log(this.currentUser());
        // console.log(JSON.parse(this.cookieService.getCookie('currentUser')));
        // console.log('monem');

    }

    public setSubDomain(sub_domain) {
        this.sub_domain = sub_domain;
        this.cookieService.setCookie('subDomain', JSON.stringify(sub_domain), 10);
    }

    public postuserLogin(object) {
        let url = "user/auth/login";
        return this.http.post(this.mainService.apiUrl + url, object);
    }

    public postschoolLogin(object) {
        let url = "school/auth/login";
        return this.http.post(this.mainService.apiUrl + url, object);
    }

    public postschoolSignup(object) {
        let url = "user/auth/school/signup";
        return this.http.post(this.mainService.apiUrl + url, object);
    }

    deleteCoolkieNavigateLogin() {

        this.cookieService.deleteCookie('currentUser');
        this.user = null;
        var quard = this.quard;
        this.cookieService.deleteCookie('currentQuard');
        this.quard = null;

        this.router.navigate(['/account/' + quard + '/login']);
    }

    auth403() {
        this.updateProfile();
        this.router.navigate(['/dashboard/' + this.quard + '/dashboards']);
    }


    /**
     * Logout the user
     */
    logout() {
        this.http.post(this.mainService.apiUrl + (this.quard) + "/auth/logout", {}).subscribe((data: any) => {
            if (data.status == 1) {
                // remove user from local storage to log user out
                this.deleteCoolkieNavigateLogin();
            }
            else {
                alert("Server Error Call Support");
            }
        });
    }

    public getAllSaasPackages() {
        let url = "general/saas/packages";
        return this.http.get(this.mainService.apiUrl + url);
    }
}

