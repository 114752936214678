import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TrackingLiveComponent } from './tracking-live/tracking-live.component';

const routes: Routes = [
  {
    path: 'tracking_live',
    component: TrackingLiveComponent
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TripsRoutingModule { }
