import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() img: string;
  @Input() showButtons: boolean;

  constructor() { }

  ngOnInit() {
  }

}
