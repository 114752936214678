import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MainService } from 'src/app/core/services/main.service';
import { formObject } from 'src/app/pages/helper/formObject';
import { ActivatedRoute, Router } from '@angular/router';

import { ServiceService } from '../service.service';
declare var $: any;
declare var jQuery: any;
import * as jquery from 'jquery';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  objectForm = new formObject;
  id = null;
  object = null;
  imageUrl = "";
  imageView = null;
  GovernmentsWithCitiesList = [];
  CitiesList = [];
  AreasList = [];
  constructor(
    private router: Router,
    private mainService: MainService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private service: ServiceService,

  ) {

    this.getGovernmentsWithCities();
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.mainService.changeBreadCrumbItems([
        { label: 'Agencies', path: '/dashboard/users/agencies', active: false },
        { label: (this.id ? "Edit" : "Create"), path: "", active: true },
      ]);
      if (this.id)
        this.getObject(this.id);
      else
        this.mainService.loadDropify();
    });

  }

  getGovernmentsWithCities(reset = false) {
    if (reset == true) {
      this.objectForm.controller.government_id.setValue(null);
      this.objectForm.controller.city_id.setValue(null);
      this.objectForm.controller.area_id.setValue(null);
    }
    this.service.getGovernmentsWithCities().subscribe((data: any) => {
      this.GovernmentsWithCitiesList = data;
      this.updateCityWhenEditAfterLoadObject();
    });
  }

  updateCityWhenEditAfterLoadObject() {
    if (this.GovernmentsWithCitiesList.length > 0 && this.object && this.object.area && this.object.area.city && this.object.area.city.government_id) {
      this.GovernmentsWithCitiesList.forEach(government => {
        if (government.id == this.object.area.city.government_id) {
          this.CitiesList = government.cities;
          this.objectForm.controller.city_id.setValue(this.object.area.city_id);
          this.updateAreaWhenEditAfterLoadObject();
        }
      });
    }
  }
  updateAreaWhenEditAfterLoadObject() {
    if (this.CitiesList.length > 0 && this.object && this.object.area && this.object.area.city) {
      this.CitiesList.forEach(city => {
        if (city.id == this.object.area.city_id) {
          this.AreasList = city.areas;
          this.objectForm.controller.area_id.setValue(this.object.area_id);
        }
      });
    }
  }

  getObject(id) {
    this.service.getObject(id).subscribe((data: any) => {
      if (!data.object) {
        this.mainService.openRoute(this.router.url.split("/")[1] + '/create');
        return;
      }
      this.object = data.object;
      this.imageUrl = data.imageUrl;
      this.objectForm.controller.name.setValue(this.object.name);
      this.objectForm.controller.active.setValue(this.object.active);
      this.objectForm.controller.address.setValue(this.object.address);
      this.objectForm.controller.email.setValue(this.object.email);
      this.objectForm.controller.phone.setValue(this.object.phone);
      this.objectForm.controller.government_id.setValue((this.object.area && this.object.area.city) ? this.object.area.city.government_id : null);

      this.mainService.loadDropify(this.object.image ? this.imageUrl + this.object.image : "");
      this.updateCityWhenEditAfterLoadObject();
    });
  }

  // loadDropify(image = "") {
  //   (function ($) {
  //     $(document).ready(function () {
  //       var defaultFile = {};
  //       if (image)
  //         defaultFile = {
  //           defaultFile: image,

  //         };
  //       this.imageView = $('.dropify').dropify(defaultFile);
  //     });
  //   })(jQuery);
  // }
  ngOnInit() {
    // this.loadDropify();
    this.objectForm.formGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      area_id: [null, Validators.required],
      city_id: [null, Validators.required],
      government_id: [null, Validators.required],
      address: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      password: (this.id) ? [''] : ['', Validators.required],
      image: [''],
      active: [1],
    });
    this.objectForm.submitAction = function (parent) {
      this.submit = true;
      this.loader = true;
      if (!this.formGroup.valid) {
        this.loader = false;
        return;
      }
      parent.service.createUpdate(this.formGroup.value, parent.id).subscribe((data: any) => {
        this.loader = false;
        this.submit = false;
        if (data.status == 1) {
          parent.mainService.show_notifications("Successfully " + (parent.id ? 'Edit' : 'Create'));
          if (!parent.id) {
            parent.mainService.openRoute(parent.router.url.split("/")[1] + "/" + parent.router.url.split("/")[2] + "/" + parent.router.url.split("/")[3] + '/edit/' + data.id);
          }
        }
        else {
          parent.mainService.showFormErrors(data.errors);
        }
      });
    };
  }
  changeGovernment(goverment) {
    this.CitiesList = goverment ? goverment.cities : [];
    this.objectForm.controller.city_id.setValue(null);
    this.objectForm.controller.area_id.setValue(null);
    this.AreasList = [];
  }


  changeCity(city) {
    this.AreasList = city ? city.areas : [];
    this.objectForm.controller.area_id.setValue(null);
  }

  imageChanged(files: FileList) {
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.objectForm.controller.image.setValue(btoa(binaryString));
  }
}
