import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropify',
  templateUrl: './dropify.component.html',
  styleUrls: ['./dropify.component.scss']
})
export class DropifyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
